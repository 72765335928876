<template>
  <div>
    <v-row>
      <v-col cols="11" md="7" class="mx-auto">
        <v-img src="@/assets/tuvoto.png"></v-img>
      </v-col>
    </v-row>
    <v-card class="mx-auto mt-8" flat>
      <v-card-title class="red--text">
        <h2>
          Confirmación
        </h2>
      </v-card-title>
      <v-card-text>
        <p class="red--text display-3">{{ message }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <router-link :to="'/'" style="text-decoration: none; color: inherit;">
          <a>
            <h3>Continuar</h3>
          </a>
        </router-link>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import confirmations from "@/services/confirmations";

export default {
  name: "EmailConfirmation",
  data: () => ({
    token: null,
    message: "Confirmando..."
  }),
  mounted() {
    let self = this;
    confirmations
      .confirm(this.$route.params.token)
      .then(response => {
        self.message = response.data.message;
      })
      .catch(error => {
        if (error.response.status == 422) {
          this.message = error.response.data.message;
        } else {
          console.log(error);
        }
      });
  }
};
</script>
